@import "normalize.css/normalize";
@import "typography";
@import "colors";

body {
  align-items: center;
  background: $background;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

#content {
  .vspacer-5rem {
    width: 100%;
    height: 5rem;
  }

  .vspacer-3rem {
    width: 100%;
    height: 3rem;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;

    #logo svg {
      opacity: 0.95;
      margin: 0 2rem 0 0;
      max-width: 7rem;
    }
  }

  #status {
    font-size: 0; /* to remove that whitespace because of inline-block */
    margin: auto;
    max-height: 13.5rem;
    max-width: 45rem;
    min-height: 13.5rem;
    min-width: 45rem;

    .statusline {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        margin: 1rem;
      }
    }
  }
}
